<template>
    <div>
        <b-card no-body class="mb-5">
            <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block v-b-toggle.accordion-2 variant="link" class="text-left font-weight-bold text-dark pl-8 pr-8 fs-102 collapsed-with-icon">
                    {{ $t('advanced_filter') }}
                </b-button>
            </b-card-header>
            <b-collapse id="accordion-2" visible accordion="my-accordion" role="tabpanel">
                <b-card-body>
                    <div class="m-form m-form--fit m--margin-bottom-20">
                        <div class="row mb-5">

                            <div class="form-group col-md-4 mt-2 mb-2">
                                <label>{{$t('customer_payments_report.customer')}}</label>
                                <multiselect v-model="customer"
                                             :placeholder="$t('customer_payments_report.customer')"
                                             label="name"
                                             track-by="id"
                                             :options="customers"
                                             :multiple="false"
                                             :taggable="false"
                                             :show-labels="false"
                                             :show-no-options="false"
                                             :show-no-results="false"
                                             @search-change="getCustomers($event)">
                                </multiselect>
                            </div>

                            <div class="form-group col-md-4 mt-2 mb-2">
                                <label for="from_date">{{$t('customer_payments_report.from_date')}}</label>
                                <input type="date" id="from_date" v-model="filters.from_date" class="form-control">
                            </div>

                            <div class="form-group col-md-4 mt-2 mb-2">
                                <label for="to_date">{{$t('customer_payments_report.to_date')}}</label>
                                <input type="date" id="to_date" v-model="filters.to_date" class="form-control">
                            </div>

                            <div class="form-group col-md-4 mt-2 mb-2">
                                <label>{{$t('group_by')}}</label>
                                <select name="" id="" v-model="filters.group_by" class="custom-select">
                                    <option :value=null>{{$t('all')}}</option>
                                    <option value="customer">{{$t('customer_payments_report.customer')}}</option>
                                    <option value="treasury">{{$t('customer_payments_report.treasury')}}</option>
                                    <option value="payment_method">{{$t('customer_payments_report.payment_method')}}</option>
                                </select>
                            </div>

                            <div class="form-group col-md-4 mt-2 mb-2">
                                <label>{{$t('order_by')}}</label>
                                <select name="" id="order_by" v-model="filters.order_by" class="custom-select">
                                    <option value="">{{$t('all')}}</option>
                                    <option value="asc">{{$t('asc')}}</option>
                                    <option value="desc">{{$t('desc')}}</option>
                                </select>
                            </div>

                            <div class="form-group col-md-4 mt-2 mb-2">
                                <label>{{$t('customer_payments_report.treasury')}}</label>
                                <!-- <select name="" id="treasury_id" v-model="filters.treasury_id" class="custom-select">
                                    <option value="">{{$t('all')}}</option>
                                    <option v-for="(row, index) in treasurys" :value="row.id" :key="index">{{row.name}}</option>
                                </select> -->
                                <multiselect v-model="treasury"
                                             :placeholder="$t('customer_payments_report.treasury')"
                                             label="name"
                                             track-by="id"
                                             :options="treasurys"
                                             :multiple="false"
                                             :taggable="false"
                                             :show-labels="false"
                                             :show-no-options="false"
                                             :show-no-results="false">
                                </multiselect>
                            </div>


                            <div class="form-group col-md-4 mt-2 mb-2">
                                <label>{{$t('customer_payments_report.employee')}}</label>
                                <!-- <select name="" id="employee_id" v-model="filters.collected_user_id" class="custom-select">
                                    <option value="">{{$t('all')}}</option>
                                    <option v-for="(row, index) in employees" :value="row.user_id" :key="index">{{row.full_name}}</option>
                                </select> -->
                                <multiselect v-model="employee"
                                             :placeholder="$t('customer_payments_report.employee')"
                                             label="full_name"
                                             track-by="id"
                                             :options="employees"
                                             :multiple="false"
                                             :taggable="false"
                                             :show-labels="false"
                                             :show-no-options="false"
                                             :show-no-results="false"
                                             @search-change="getEmployees($event)">
                                </multiselect>
                            </div>

                            <div class="form-group col-md-4 mt-2 mb-2">
                                <label>{{$t('customer_payments_report.currency')}}</label>
                                <select name="" id="currency_id" v-model="filters.currency_id" class="custom-select">
                                    <option value="">{{$t('all')}}</option>
                                    <option v-for="(row, index) in currencies" :value="row.id" :key="index">{{row.name}}</option>
                                </select>
                            </div>

                            <div class="form-group d-inline-flex col-md-4 mt-10">
                                <button type="button" @click="doFilter" class="btn btn-primary mt-auto mr-2" id="m_search">
                                    {{$t('display_report')}}
                                </button>
                                <button type="button" @click="resetFilter" class="btn btn-danger mt-auto mr-2" id="resetFilter">
                                    <i class="fas fa-trash-restore"></i> {{$t('reset_search')}}
                                </button>
                            </div>
                        </div>
                    </div>
                </b-card-body>
            </b-collapse>
        </b-card>

        <!--begin::customer-->
        <div class="card card-custom mt-5">

            <div class="card-body">

                <div class="col-md-12 mb-5">
                    <div class="m-form m-form--fit m--margin-bottom-20">
                        <div class="row">
                            <div class="col-12 mt-10" :id="'summaryTableDetails'+filters.group_by+'Title'">
                                <!-- Clients Installments - Group By Client -->
                                <h4 class="text-center">{{ $t('customer_payments_report.customer_payments_report')}}</h4>
                                <h5 class="text-center"><span v-if="filters.from_date">{{ $t('from') }} 
                                    {{filters.from_date}}</span> <span v-if="filters.to_date">{{ $t('to') }} {{filters.to_date}}</span>
                                </h5>
                            </div>
                            <div class="col-12 mt-10" v-if="dataChartList">
                                <doughnut :data-list="dataChartList"></doughnut>
                            </div>
                            <div class="col-12 mt-10 d-flex justify-content-end">
                                <b-dropdown right :text="$t('export')" class="mr-2 ml-2">
                                    <button class="dropdown-item" @click="convertTableToExcelReport">
                                        <i class="la la-file-excel"></i>{{$t('excel')}}
                                    </button>
                                    <button class="dropdown-item" @click="printData('summaryTableDetails'+filters.group_by, 'print')">
                                        <span><i class="fa fa-print"></i> <span>{{$t('print')}}</span></span>
                                    </button>
                                    <button class="dropdown-item" @click="printData('summaryTableDetails'+filters.group_by, 'pdf')">
                                        <span><i class="fa fa-file-pdf"></i> <span>{{$t('export_pdf')}}</span></span>
                                    </button>
                                </b-dropdown>
                            </div>
                            <div class="col-12 mt-10">
                                <div class="table-responsive">
                                    <table class="table table-bordered" :id="'summaryTableDetails'+filters.group_by">
                                        <thead>

                                        <tr id="myRow">
                                            <th>#</th>
                                            <th>{{$t('customer_payments_report.date')}}</th>
                                            <th>{{$t('customer_payments_report.client_number')}}</th>
                                            <th>{{$t('customer_payments_report.client_name')}}</th>
                                            <th>{{$t('customer_payments_report.type')}}</th>
                                            <th>{{$t('customer_payments_report.document_number')}}</th>
                                            <th>{{$t('customer_payments_report.payment_method')}}</th>
                                            <th>{{$t('customer_payments_report.treasury_bank')}}</th>
                                            <th>{{$t('customer_payments_report.assigned_staff')}}</th>
                                            <th>{{$t('customer_payments_report.amount')}} ({{currency_name}})</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <template v-for="(row, index) in data">
                                            <tr :key="'c'+index">
                                                <td colspan="10">{{row.label}}</td>
                                            </tr>
                                            <tr v-for="(row2, index2) in row.details" :key="'c'+getRandom()+index2">
                                                <td>{{row2.id}}</td>
                                                <td>{{row2.payment_date}}</td>
                                                <td>{{row2.client_code}}</td>
                                                <td>{{row2.client_name}}</td>
                                                <td>{{row2.type}}</td>
                                                <td>{{row2.document_number}}</td>
                                                <td>{{row2.payment_method_name}}</td>
                                                <td>{{row2.treasury}}</td>
                                                <td>{{row2.assigned_staff}}</td>
                                                <td>{{row2.total}}</td>
                                            </tr>
                                            <tr :key="'d'+index">
                                                <td colspan="9">{{$t('total')}}</td>
                                                <td>{{row.total.sum_total}}</td>
                                            </tr>
                                        </template>

                                        </tbody>
                                        <tfoot>
                                        <tr v-if="dataTotal">
                                            <td colspan="9">{{$t('total')}}</td>
                                            <th>{{ dataTotal}}</th>
                                        </tr>
                                        </tfoot>
                                    </table>

                                </div>

                            </div>
                        </div>
                    </div>

                </div>
                <div class="col-12 text-center" v-if="page">
                    <button class="btn btn-warning" @click="loadMore">
                        <p class="mb-0"><b-icon icon="arrow-counterclockwise" font-scale="1"></b-icon> {{$t('load_more')}}</p>
                    </button>
                </div>
                <!-- End Filter -->

            </div>
        </div>
      <div class="selector-export" id="selectorExport"></div>
        <!--end::customer-->
    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";
    import TableToExcel from "@linways/table-to-excel";
    import Doughnut from "../components/Doughnut";
    import exportPDFInnerAction from "@/core/plugins/export-jspdf-inner-action";

    export default {
        name: "index-customer-sales-report",
        components: {'doughnut': Doughnut},
        data() {
            return {
                mainRoute: '/reports/customer-payment-report/data-list',
                mainRouteChart: '/reports/customer-payment-report/data-list-chart',
                mainRouteDependency: 'base/dependency',

                data: [],
                dataTotal: null,
                dataChartList: null,

                customers: [],
                treasurys: [],
                employees: [],
                currencies: [],


                data_more_details: [],
                details_total_sum: [],


                filters: {
                    customer_id: null,
                    group_by: null,
                    from_date: null,
                    to_date: null,
                    order_by: null,
                    treasury_id: null,
                    collected_user_id: null,
                    currency_id: null,
                },
                currency_name: null,
                customer: null,
                treasury: null,
                employee: null,
                json_meta: [
                    [{
                        key: "charset",
                        value: "utf-8",
                    },],
                ],
                page : 0,
            }
        },
        watch: {
            customer: function (val) {
                if (val) {
                    this.filters.customer_id = val.id;
                } else {
                    this.filters.customer_id = null;
                }
            },
            treasury: function (val) {
                if (val) {
                    this.filters.treasury_id = val.id;
                } else {
                    this.filters.treasury_id = null;
                }
            },
            employee: function (val) {
                if (val) {
                    this.filters.collected_user_id = val.id;
                } else {
                    this.filters.collected_user_id = null;
                }
            },
        },
        methods: {
            convertTableToExcelReport() {
                this.setReportLog('excel', 'customer payments report');
                TableToExcel.convert(document.getElementById('summaryTableDetails' + this.filters.group_by), {
                    name: this.$t('customer_payments_report.customer_payments_report') + '.xlsx',
                    sheet: {
                        name: 'Sheet 1'
                    }
                });
            },

            getRandom() {
                return Math.floor(Math.random() * 10000);
            },

            async getAuthUser() {
                await ApiService.get(`/get_auth`).then((response) => {
                    this.user = response.data.data;
                    this.currency_name = response.data.data.currency_name;
                    if (this.user) {
                        this.user_name = this.user.name;
                    }
                });
            },

            // selectFilter() {
            //     this.getData();
            // },

            doFilter() {
                this.page = 1;
                this.getData();
                this.getDataChartList();
            },
            resetFilter() {
                this.customer = null;
                this.treasury = null;
                this.employee = null;
                this.filters.customer_id = null;
                this.filters.group_by = null;
                this.filters.from_date = null;
                this.filters.to_date = null;
                this.filters.order_by = null;
                this.filters.treasury_id = null;
                this.filters.collected_user_id = null;
                this.filters.currency_id = null;
                this.getData();
                this.getDataChartList();

            },
            dataReport() {
                this.setReportLog('excel', 'customer payments report');
                return this.data;
            },
            loadMore() {
                this.page = this.page? (this.page + 1) : 1;
                this.getData();
                this.getDataChartList();
            },
            getData() {
                ApiService.get(this.mainRoute, {params: {...this.filters, page:(this.page? this.page : 1)}}).then((response) => {
                    this.data = response.data.data;
                    this.dataTotal = response.data.total;

                });
            },

            getDataChartList() {
                ApiService.get(this.mainRouteChart, {params: {...this.filters, page:(this.page? this.page : 1)}}).then((response) => {
                    this.dataChartList = response.data;
                });
            },


            getCustomers(filter) {
                if(filter && filter.length >= 3)
                    ApiService.get(this.mainRouteDependency + "/customers", {params:{filter: filter}}).then((response) => {
                        this.customers = response.data.data;
                    });
            },

            getTreasuries() {
                ApiService.get(this.mainRouteDependency + "/treasuries").then((response) => {
                    this.treasurys = response.data.data;
                });
            },

            getEmployees(filter) {
                if(filter && filter.length >= 3)
                ApiService.get(this.mainRouteDependency + "/employees", {params:{name: filter}}).then((response) => {
                    this.employees = response.data.data;
                });
            },

            getCurrencies() {
                ApiService.get(this.mainRouteDependency + "/currencies").then((response) => {
                    this.currencies = response.data.data;
                });
            },

            printData(tableId, type) {
                this.setReportLog('pdf', 'customer payments report');

                // let divToPrint = document.getElementById(tableId);
                // let divTitleToPrint = document.getElementById(tableId + 'Title');
                // let newWin = window.open("");
                // newWin.document.write("<link rel='stylesheet' href='https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css'>" + divTitleToPrint.outerHTML + divToPrint.outerHTML);
                // setTimeout(() => {
                //     newWin.print();
                //     newWin.close();
                // }, 100)
              exportPDFInnerAction(tableId , type, this.$t('customer_payments_report.customer_payments_report'), this.$t('customer_payments_report.customer_payments_report'));

            },
            /**
             * register log
             */
            setReportLog(key, description){
                ApiService.post("reports/system_activity_log/log_type",{
                    key:key,
                    description: description,
                });
            }
        },
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.clients_reports"),route:'/clients/reports'}, {title: this.$t("MENU.customer_payments_report")}]);

            // this.getCustomers();
            this.getTreasuries();
            // this.getEmployees();
            this.getCurrencies();
            this.getAuthUser();

        },
    };
</script>
<style>
.html2pdf__overlay{
  opacity: 0 !important;
  z-index: -1000;

}
.selector-export{
  opacity: 0 !important;
  z-index: -1000;
  width: 0.0001px;
  height: 0.0001px;
  overflow: auto;
}
</style>